import React from "react";

interface VideoComponentProps {
  videoSrc: string;
  ariaLabel: string;
  width?: string;
  caption?: React.ReactNode;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  videoSrc,
  ariaLabel,
  caption,
  width = "400", // Set the default value for width here
}) => {
  return (
    <>
      <video
        width={width}
        className="articleCustomImage"
        controls
        aria-label={ariaLabel}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {caption && <div className="emphasis">{caption}</div>}
    </>
  );
};

export default VideoComponent;
